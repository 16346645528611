import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TuneIcon from "@mui/icons-material/Tune";
import {
  fetchAllArtists,
  fetchAllArtistsByGenre,
} from "../../functions/general";
import {
  getArtistsByGenre,
  getArtistsByGenres,
  getArtistsStageName,
  newGetArtistsByGenres,
} from "../../functions/artist";
import { useNavigate, useLocation } from "react-router-dom";
import lupa from "../../assets/images/lupa.png";

const SearchButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  backgroundColor: "#af8e56",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#af8e56",
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#272727",
  borderRadius: "12px",
  padding: theme.spacing(0.5, 2),
  marginTop: theme.spacing(4),
  width: "100%",
  border: "1px solid #A4A4A4",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
}));


const SearchBar = ({ onSearch, searchCriteriaPage, setSearchCriteriaPage, isHomePage }) => {
  const [searchCriteria, setSearchCriteria] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.searchCriteria) {
      setSearchCriteria(location.state.searchCriteria);
      searchArtists(location.state.searchCriteria);
    }
  }, [location.state]);

  const searchArtists = async (criteria = searchCriteria) => {
    let results = [];
    if (isHomePage) {
      navigate("/search", { state: { searchCriteria: criteria } });
    } else {
      try {
        if (criteria === "") {
          results = await fetchAllArtists("artist");
        } else {
          const genres = criteria.split(",");
          if (genres.length > 1) {
            results = await getArtistsByGenres(genres);
          } else {
            results = await getArtistsByGenre(criteria);
          }
          if (results.length === 0) {
            results = await getArtistsStageName(criteria);
          }
        }
        setSearchCriteriaPage(criteria);
        onSearch(results);
      } catch (error) {
        console.error("Error during search:", error.message);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchArtists();
    }
  };

  return (
    <SearchBox>
      <StyledTextField
        variant="outlined"
        placeholder="Busca: Pop, Rock, Baladas, etc..."
        fullWidth
        value={searchCriteria}
        onChange={(e) => setSearchCriteria(e.target.value)}
        onKeyPress={handleKeyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                src={lupa}
                alt="lupa"
                style={{ width: 24, height: 24, marginRight: "8px", marginLeft: "0px" }}
              />
            </InputAdornment>
          ),
          style: { color: "#ffffff" },
        }}
        sx={{ backgroundColor: "#272727" }}
      />
      <SearchButton variant="contained" onClick={() => searchArtists()}>
        Buscar
      </SearchButton>
    </SearchBox>
  );
};

export default SearchBar;
