import { supabase } from "../lib/helper/supabaseClient";


export const registerFaro = async (
  created_at,
  eventDate,
  clientID,
  artistID,
  payment,
  details,
  venueID,
  address,
  name,
  media,
  toTime,
  startTime,    
  endTime,       
) => {
  try {
    const { data, error } = await supabase
      .from("faro")
      .insert([
        {
          created_at,
          eventDate,
          clientID,
          artistID,
          payment,
          details,
          venueID,
          address,
          name,
          media,
          toTime,
          startTime,    
          endTime,
        },
      ])
      .select();

    if (error) {
      throw error;
    }

    return data[0];
  } catch (error) {
    console.error("Error creating faro record:", error.message);
    throw error;
  }
};


export const deleteFaro = async (faroId) => {
  const { data, error } = await supabase
    .from("faro")
    .delete()
    .eq("id", faroId);

  if (error) {
    throw error;
  }

  return data;
};



export const getFarosByClientID = async (id) => {
  const { data, error } = await supabase
    .from("faro")
    .select("*")
    .eq("clientID", id);

  if (error) {
    console.error("Error fetching data:", error);
  }

  return data; 
};



export const getFaroById = async (faroId) => {
  const { data, error } = await supabase
    .from('faro')
    .select('*')
    .eq('id', faroId)
    .single();

  if (error) {
    throw error;
  }

  return data;
};

export const updateFaro = async (
  faroId, 
  startTime,     
  payment,
  details,
  clientID,
  artistID,
  venueID,
  name,
  address,
  endTime,       
  eventDate     
) => {
  try {
    const { data, error } = await supabase
      .from("faro")
      .update({
        eventDate,   
        startTime,   
        endTime,     
        payment,
        details,
        clientID,
        artistID,
        venueID,
        name,
        address,
      })
      .eq("id", faroId) 
      .select();

    if (error) {
      throw error;
    }

    return data[0]; 
  } catch (error) {
    console.error("Error updating faro record:", error.message);
    throw error;
  }
};
