import React from 'react';
import { extractTikTokVideoId, extractInstagramVideoId } from '../../functions/stringManipulation';


const VideoEmbed = ({ embedUrl ,title = 'Embedded Video', width = '325', height = '580', platform = 'youtube' }) => {
  
  // Render TikTok embed using iframe
  if (platform === 'tiktok') {
    // Extract the video ID from the URL
    const tik_tok_code = extractTikTokVideoId(embedUrl)
    
    const embedPlayerUrl = `https://www.tiktok.com/player/v1/${tik_tok_code}`; // Construct embed URL

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          margin: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <iframe
          src={embedPlayerUrl}
          width={width}
          height={height}
          style={{
            border: 'none',
            backgroundColor: 'black',
          }}
          allowFullScreen
          title={title}
        ></iframe>
      </div>
    );
  } else if ( platform == "instagram"){
    const instagram_code = extractInstagramVideoId(embedUrl)
    
    const embedPlayerUrl = `https://www.instagram.com/p/${instagram_code}/embed`; // Construct embed URL

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          margin: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <iframe
          src={embedPlayerUrl}
          width={width}
          height={height}
          style={{
            border: 'none',
            backgroundColor: 'black',
          }}
          allowFullScreen
          title={title}
        ></iframe>
      </div>
    );

  }

  // Default to iframe for YouTube, Vimeo, etc.
  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
        maxWidth: '100%',
        background: '#000',
      }}
    >
      <iframe
        src={embedUrl}
        title={title}
        width={width}
        height={height}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoEmbed;
