import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1, 6),
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  minHeight: '47px',
  minWidth: '240px',
  width: '196px',
  borderRadius: '8px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 5),
  },
}));

const Icon = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: '39px',
  marginRight: theme.spacing(1),
}));

const Text = styled(Typography)(({ theme }) => ({
  width: '156px',
  textAlign: 'center',
}));

function SocialSignInButton({ provider, iconSrc, altText }) {
  return (
    <CustomButton>
      <Box display="flex" alignItems="center">
        <Icon loading="lazy" src={iconSrc} alt={altText} />
        <Text variant="body1">
          Sign in with {provider}
        </Text>
      </Box>
    </CustomButton>
  );
}

export default SocialSignInButton;
