import React from 'react';
import { Grid, Typography, CardContent, CardMedia, Box } from '@mui/material';

import estrellaN from '../../assets/images/estrellaN.png';
import faroN from '../../assets/images/faroN.png';
import facebookN from '../../assets/images/facebookN.png';

const ArtistStats = ({ artist, StyledCard }) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <StyledCard>
              <CardContent>
                <Grid container>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: 2,
                      }}
                    >

                      <CardMedia
                        component="img"
                        sx={{ width: 'auto', maxHeight: '55px', objectFit: 'contain' }}
                        image={faroN}
                        alt="Faros"
                      />

                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                  <Typography gutterBottom sx={{ fontWeight: 'bold', fontSize:18, fontFamily: "Poppins" }}>
                      Faros
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize:18  }}>
                      {artist.faros || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={6}>
            <StyledCard>
              <CardContent sx={{paddingY:2}}>
                <Grid container>
                  <Grid item
                    xs={4}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: 2,
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{ width: 'auto', maxHeight: '30px', objectFit: 'contain' }}
                        image={estrellaN}
                        alt="Reviews"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography gutterBottom sx={{ fontWeight: 'bold', fontSize:18, fontFamily: "Poppins" }}>
                      Reviews
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize:18  }}>
                      {artist.reviewsNumber || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <StyledCard sx={{ mb: 0 }}>
          <CardContent>
            <Grid container>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: 2,
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 'auto', maxHeight: '50px', objectFit: 'contain' }}
                    image={facebookN}
                    alt="Facebook"
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
              <Typography gutterBottom sx={{ fontWeight: 'bold', fontSize:18, fontFamily: "Poppins" }}>
                  Social Media
                </Typography>
                <Typography sx={{ fontWeight: 100, fontFamily: "Poppins" }}>
                  Facebook
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>

      </Grid>
    </Grid>
  );
};

export default ArtistStats;
