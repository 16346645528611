import React, { useRef, useState } from "react";
import { Box, Typography, IconButton, Paper } from "@mui/material";
import { CloudUpload as CloudUploadIcon, Cancel as CancelIcon } from "@mui/icons-material";

function UploadArea({ onFileChange }) {
  const fileInputRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    handleFilePreview(files);
    onFileChange(files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleFilePreview(files);
    onFileChange(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  // Generate previews and save file info
  const handleFilePreview = (files) => {
    const filePreviews = files.map((file) => ({
      name: file.name,
      previewURL: file.type.startsWith("image/") ? URL.createObjectURL(file) : null,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...filePreviews]);
  };

  // Handle file removal
  const handleRemoveFile = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
  };

  return (
    <>
      {/* Upload Area */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "97%",
          height: "271px",
          backgroundColor: "white",
          borderRadius: 2,
          border: "2px dashed",
          borderColor: "grey.500",
          overflow: "hidden",
          textAlign: "center",
          cursor: "pointer",
          "&:hover": {
            borderColor: "primary.main",
          },
          [theme => theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
        role="button"
        tabIndex="0"
        aria-label="Upload area. Drag your files or click to browse"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={openFileDialog}
      >
        <IconButton
          sx={{
            mb: 2,
            width: 36,
            height: 36,
          }}
        >
          <CloudUploadIcon />
        </IconButton>
        <Typography variant="body2" color="text.secondary">
          Drag your files or click to browse
        </Typography>
        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: "none" }}
        />
      </Box>

      {/* File Preview Area */}
      {uploadedFiles.length > 0 && (
        <Box sx={{ mt: 2, width: "97%" }}>
          {uploadedFiles.map((file, index) => (
            <Paper
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                p: 2,
                backgroundColor: "grey.100",
                borderRadius: 2,
              }}
            >
              {/* Image Thumbnail */}
              {file.previewURL ? (
                <Box
                  component="img"
                  src={file.previewURL}
                  alt={file.name}
                  sx={{
                    width: 50,
                    height: 50,
                    objectFit: "cover",
                    borderRadius: 1,
                    mr: 2,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: 50,
                    height: 50,
                    backgroundColor: "grey.300",
                    borderRadius: 1,
                    mr: 2,
                  }}
                />
              )}

              {/* File Name */}
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {file.name}
              </Typography>

              {/* Remove File Button */}
              <IconButton
                aria-label="Remove file"
                onClick={() => handleRemoveFile(index)}
              >
                <CancelIcon />
              </IconButton>
            </Paper>
          ))}
        </Box>
      )}
    </>
  );
}

export default UploadArea;
