// Footer.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import footer from '../../assets/images/footer.jpg';

const StyledFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  color: "#fff",
  textAlign: "center",
  backgroundImage: `url(${footer})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

function Footer() {
  return (
    <StyledFooter >
      <Typography variant="h4" component="h2" gutterBottom>
        Ilumina tu negocio. Encuentra a tu <span style={{ color: "#af8e56" }}>artista</span> perfecto.
      </Typography>
    </StyledFooter>
  );
}

export default Footer;
