import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import SignInForm from './SignInForm';
import SocialSignInButton from './SocialSignInButton';
import "@fontsource/poppins";
import "@fontsource/playfair-display"
import { supabase } from '../lib/helper/supabaseClient';
import { Navigate, useNavigate } from "react-router-dom";


const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  height: '100vh',
  display: 'flex',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const LeftSection = styled(Box)(({ theme }) => ({
  width: '60vw',  // Use viewport width for scalable sizing
  position: 'relative',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
    height: '50vh',
  },
}));

const RightSection = styled(Box)(({ theme }) => ({
  width: '40vw',  // Scales the width based on viewport
  padding: '4vw',  // Use vw for consistent spacing
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '500px',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
    padding: '3vw',
  },
}));

const BackgroundImage = styled('img')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
});

const LargeText = styled(Typography)(({ theme }) => ({
  fontSize: '249.799px',  // Scales the text size with the viewport width
  fontWeight: '400',
  textAlign: 'center',
  fontFamily: "Playfair Display",
  color: 'rgb(57, 57, 57,0.8)',
  position: 'absolute',
  zIndex: 2,
  top: '50%',  // Center vertically
  left: '50%',  // Center horizontally
  transform: 'translate(-50%, -50%)',  // Center text precisely
  [theme.breakpoints.down('md')]: {
    fontSize: '5vw',  // Reduce size on smaller screens
  },
}));

const SignInTitle = styled(Typography)(({ theme }) => ({
  fontSize: '3vw',  // Dynamic sizing for the sign-in title
  fontWeight: '300',
  marginBottom: '2vh',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5vw',
    textAlign: 'left',
  },
}));

const Separator = styled('hr')({
  border: 'none',
  borderTop: '1px solid #ccc',
  margin: '2vh 0',  // Use vh for vertical spacing
});

const Elipse = styled(Box)(({ theme }) => ({
  width: "410px",
  height: "401px",
  flexShrink: 0,
  borderRadius: "468px",
  backgroundColor: "#AC8C46",
  filter: 'blur(150px)',
  zIndex: 2
}));



const SignInPage = () => {


  const navigate = useNavigate();
  return (
    <MainContainer>
      {/* Left Section with Background Image and Text */}
      <LeftSection>
      <Elipse>

      </Elipse>
        <BackgroundImage
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc5b9183530ce2e7f0c5089587a6d4e9602b110704e7b5a537d349d8b69d864a?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d"
          alt="Background"
        />
        <LargeText>of<span style={{ fontWeight: '400', color: "#393939"}}>ar</span>os</LargeText>
      </LeftSection>

      {/* Right Section with Form and Buttons */}
      <RightSection>
        <Typography variant="body2" sx={{ textAlign: 'right', marginBottom: '2vh' }}>
          Don’t have an account?{' '}
          <Button href="#" variant="text" sx={{ color: "#AC8C46" }} onClick={()=>{navigate('/register')}}>
            Sign up
          </Button>
        </Typography>

        <SignInTitle>
          Sign in to <span style={{ fontWeight: '600' }}>your account</span>
        </SignInTitle>

        <Box display="flex" flexDirection="column" gap="2vh" width="100%">
          <Box display="flex" gap="2vw" justifyContent="left">
            {/* <SocialSignInButton
              provider="Facebook"
              iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/5ecd4678beead9257e38d85275dbaadd724f2868c50a365e497a8ae4e9f15b3d"
              altText="Facebook logo"
            />
            <SocialSignInButton
              provider="Google"
              iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/1db3863f9b19eb9628600a62435f5ad773e97e1fd92888d62d0202ed91abe662"
              altText="Google logo"
            /> */}
          </Box>

          <Separator />

          <SignInForm />
        </Box>
      </RightSection>
    </MainContainer>
  );
};

export default SignInPage;
