import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import defaultImg from "../../assets/images/defaultImg.jpg";
import faro from "../../assets/images/faro.png";
import { styled } from "@mui/material/styles";

export const BookNowButton = ({ onClick, text }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{
      backgroundColor: "#af8e56",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#8e7246",
      },
      paddingX: 1,
      fontFamily: "Poppins",
    }}
  >
    {text}
  </Button>
);

const StyledTypography = styled(Typography)({
  fontFamily: 'Poppins',
  color: '#626262',  
});
const ArtistCard = ({ artist, popup, form }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => setShowFullDescription(!showFullDescription);
  const navigate = useNavigate();

  const handleClick = () => {
    if (!popup) {
      navigate(`/profile/artist/${artist.stageName}`, { state: { artist } });
    } else {
      if (typeof popup === "function") {
        popup(artist);
        console.log("Artist selected: ", artist);
      }
    }
  };

  const truncateDescription = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length - 3) + "...";
  };

  const capitalizeWords = (str) => {
    console.log('str',str);
    return str.split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
  };

  const isGoogleDriveLink = (url) => {
    return url.includes("drive.google.com");
  };
  function isValidImageFormat(url) {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }

  
  const artistImage = !artist.profile_picture || isGoogleDriveLink(artist.profile_picture) || !isValidImageFormat(artist.profile_picture) ? defaultImg : artist.profile_picture;

  const buttonText = form ? "Ver perfil" : "Contratar";

  return (
    <Card sx={{ maxWidth: 400, borderRadius: 5, overflow: "hidden", fontFamily: "Poppins" }}>
      <Box sx={{ padding: 2 }}>  
        <CardMedia
          component="img"
          height="240"
          image={artistImage}
          onClick={handleClick}
          sx={{
            cursor: "pointer",
            borderRadius: 2,
            objectFit: "cover",
            width: "100%",
            height: "240px",
          }}
        />
      </Box>
      <CardContent>
        {artist.description && (
          <>
            <StyledTypography variant="body2" color="text.secondary" gutterBottom>
              {showFullDescription ? artist.description : truncateDescription(artist.description, 90)}
            </StyledTypography>
            {artist.description.length > 90 && (
              <Button
                size="small"
                onClick={toggleDescription}
                sx={{ color: "#af8e56", textTransform: "none", fontFamily: "Poppins" }}
              >
                {showFullDescription ? "Ver menos" : "Ver más"}
              </Button>
            )}
          </>
        )}
        <StyledTypography sx={{ mt: 1, fontWeight: 100, fontFamily: "Poppins" }}>
          Desde: $<span style={{ fontWeight: 700 }}>{artist.rate}</span>
        </StyledTypography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: 50,
                height: 50,
                borderRadius: "50%",
                overflow: "hidden",
                mr: 2,
                flexShrink: 0,
              }}
            >
              <CardMedia
                component="img"
                image={artistImage}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box>
              <StyledTypography variant="body1" sx={{ fontWeight: "bold" }}>
                {capitalizeWords(artist.stageName)}
              </StyledTypography>
              <Box sx={{ display: "flex", alignItems: "center", flexWrap: 'nowrap' }}>
                <StyledTypography variant="body2" sx={{ fontSize: 16, display: 'flex', alignItems: 'center' }}>
                  ⭐ {artist.stars || 0} &nbsp;
                  <CardMedia
                    component="img"
                    image={faro}
                    sx={{ width: 16, height: 16, marginRight: 1 }}
                  /> {artist.lighthouseNumber || 0}
                </StyledTypography>
              </Box>
            </Box>
          </Box>
          <Box>
            <BookNowButton onClick={handleClick} text={buttonText} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ArtistCard;
