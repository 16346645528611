import React, { useEffect, useState } from "react";
import { Typography, styled, Box } from "@mui/material";
import EventForm from './createFaroForm';
import { useLocation, useParams } from "react-router-dom";
import { getFaroById } from '../functions/faro';


const CreationTitle = styled(Typography)(({ theme }) => ({
  fontSize: '3vw',
  fontWeight: '400',
  marginBottom: '2vh',
  fontStyle: "normal",
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5vw',
    textAlign: 'left',
  },
}));

const CreateFaroPage = () => {
  const location = useLocation();
  const { faroId } = useParams();
  const [faroData, setFaroData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const artist = location.state?.artist;
  const date = location.state?.date;
  const startTime = location.state?.startTime;
  const endTime = location.state?.endTime;
  

  useEffect(() => {
    const fetchFaroData = async () => {
      if (faroId) {
        try {
          setIsLoading(true);
          const data = await getFaroById(faroId);
          if (data.details) {
            data.details = JSON.parse(data.details);
          }
          setFaroData(data);
        } catch (error) {
          console.error('Error fetching Faro data:', error);
        } finally {

          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchFaroData();
  }, [faroId]);

  return (
    <div>
       <Box sx={{ backgroundColor: "#171717", height: "4vh" }}></Box>
      <Box sx={{ mr: "158px", ml: "158px", mt: "50px", mb: "100 px" }}>
     
        <CreationTitle style={{ fontFamily: "Poppins" }}>
          {faroId ? 'Edit an' : 'Create an'} <span style={{ fontWeight: '600' }}>event</span>
        </CreationTitle>
        {isLoading ? (
          <Typography variant="h6" align="center">Loading event data...</Typography>
        ) : (
          <EventForm
            faroData={faroData}
            date={date}
            startTime={startTime}
            endTime={endTime}
          />

        )}
      </Box>
    </div>
  );
};

export default CreateFaroPage;
