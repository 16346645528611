import React from "react";
import { Box, Typography } from "@mui/material";
import UploadArea from "./UploadArea";

function MediaUpload({ files, handleFileOnChange }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        gap: 2,
        width: "100%"
      }}
    >
      {/* Title */}
      <Typography
        variant="h5"
        fontWeight="bold"
        color="text.primary"
        sx={{ width: '100%' }}
      >
        Media
      </Typography>

      {/* Description */}
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mt: 1, width: '100%' }}
      >
        Agrega tus documentos aquí, máximo 5 archivos
        <Typography component="span" fontWeight="medium" color="text.disabled">
          *
        </Typography>
      </Typography>

      {/* Upload Area */}
      <UploadArea onFileChange={handleFileOnChange} />

      {/* Supported File Types */}
      <Typography
        variant="body2"
        color="text.disabled"
        sx={{ mt: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%' }}
      >
        Solo soporta .jpg, .png, .svg, y archivos zip
      </Typography>
    </Box>
  );
}

export default MediaUpload;