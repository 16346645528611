import React from 'react';
import { Box, TextField, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '240px',
  width: '1011px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const TimeSelection = ({ dateValue, onDateChange, startTimeValue, onStartTimeChange, endTimeValue, onEndTimeChange, themeD }) => {
  const theme = useTheme();

  const handleDateChange = (event) => {
    const newDateValue = event.target.value;
    onDateChange(newDateValue);
  };

  const handleStartTimeChange = (event) => {
    const newStartTimeValue = event.target.value;
    onStartTimeChange(newStartTimeValue);
  };

  const handleEndTimeChange = (event) => {
    const newEndTimeValue = event.target.value;
    onEndTimeChange(newEndTimeValue);
  };

  const textColor = themeD === 'dark' ? '#ffffff' : theme.palette.text.primary;
  const borderColor = themeD === 'dark' ? '#ffffff' : theme.palette.text.primary;

  return (
    <StyledBox>
      <Typography
        variant="body1"
        sx={{ fontWeight: 'light', color: textColor, maxWidth: '100%' }}
      >
        ¿Cuándo es el evento?{' '}
        <Typography component="span" sx={{ fontWeight: 'medium', color: textColor }}>
          *
        </Typography>
      </Typography>

      <Box sx={{ mt: 2, width: '100%', color: textColor }}>
        <TextField
          id="eventDate"
          name="eventDate"
          type="date"
          variant="outlined"
          value={dateValue}
          onChange={handleDateChange}
          InputProps={{
            sx: {
              paddingRight: 3,
              paddingLeft: 3,
              minHeight: '47px',
              color: textColor,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
            },
          }}
          fullWidth
          sx={{
            minWidth: '240px',
            width: '435px',
            height: '47px',
            color: textColor,
          }}
        />
      </Box>

      <Typography
        variant="body1"
        sx={{ fontWeight: 'light', color: textColor, maxWidth: '100%', mt: 2 }}
      >
        ¿Cuánto va a tomar el evento? Marca la hora de inicio y fin{' '}
        <Typography component="span" sx={{ fontWeight: 'medium', color: textColor }}>
          *
        </Typography>
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          alignItems: 'center',
          mt: 2,
          width: '100%',
          color: textColor,
          [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
          },
        }}
      >
        <TextField
          id="eventStartTime"
          name="eventStartTime"
          type="time"
          variant="outlined"
          value={startTimeValue}
          onChange={handleStartTimeChange}
          InputProps={{
            sx: {
              paddingRight: 3,
              paddingLeft: 3,
              minHeight: '47px',
              color: textColor,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
                filter: themeD === 'dark' ? 'invert(1)' : 'none',
              },
            },
          }}
          fullWidth
          sx={{
            minWidth: '240px',
            width: '435px',
            height: '47px',
            color: textColor,
          }}
        />

        <TextField
          id="eventEndTime"
          name="eventEndTime"
          type="time"
          variant="outlined"
          value={endTimeValue}
          onChange={handleEndTimeChange}
          InputProps={{
            sx: {
              paddingRight: 3,
              paddingLeft: 3,
              minHeight: '47px',
              color: textColor,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
              },
              '& input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
                filter: themeD === 'dark' ? 'invert(1)' : 'none',
              },
            },
          }}

          fullWidth
          sx={{
            minWidth: '240px',
            width: '435px',
            height: '47px',
            color: textColor,
          }}
        />
      </Box>
    </StyledBox>
  );
};

export default TimeSelection;
