// src/Analytics.js
import ReactGA from 'react-ga4';

const initAnalytics = () => {
    ReactGA.initialize('G-ESXND47KRT');
};

const logPageView = (page) => {
    ReactGA.send({ hitType: 'pageview', page });
};

export default {
    initAnalytics,
    logPageView,
};
