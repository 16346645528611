import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import PhoneIcon from "@mui/icons-material/Phone";
import EditIcon from "@mui/icons-material/Edit";
import venueDefaultImg from "../../assets/images/venueDefault.jpeg";

const VenueCard = ({ venue, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [updatedVenue, setUpdatedVenue] = useState(venue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUpdatedVenue(venue);
  }, [venue]);

  const handleOpen = () => {
    setUpdatedVenue(venue);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setUpdatedVenue({
      ...updatedVenue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await onUpdate(updatedVenue);
      handleClose();
    } catch (error) {
      console.error("Error updating venue:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
          borderRadius: 2,
          overflow: "hidden",
          border: "1px solid #d0d0d0",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={venue.venuePhoto || venueDefaultImg}
          sx={{
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          }}
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography gutterBottom variant="h6" component="div">
              {venue.venueName}
            </Typography>
            <IconButton onClick={handleOpen} aria-label="edit" size="small">
              <EditIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {venue.venueDescription || "Descripción no disponible."}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <LocationOnIcon sx={{ color: "#af8e56", mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              {venue.venueAddress || "Dirección no disponible."}
            </Typography>
          </Box>
          {venue.phoneNumber && (
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <PhoneIcon sx={{ color: "#af8e56", mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {venue.phoneNumber}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <PeopleIcon sx={{ color: "#af8e56", mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              Capacidad máxima: {venue.venueAmountPeople || "No especificado"}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Editar Información del Venue</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nombre del Venue"
            name="venueName"
            value={updatedVenue.venueName || ""}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Descripción"
            name="venueDescription"
            value={updatedVenue.venueDescription || ""}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Dirección"
            name="venueAddress"
            value={updatedVenue.venueAddress || ""}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Número de Teléfono"
            name="venuePhoneNumber"
            value={updatedVenue.phoneNumber || ""}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Capacidad Máxima"
            name="venueAmountPeople"
            type="number"
            value={updatedVenue.venueAmountPeople || ""}
            onChange={handleInputChange}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ color: "black" }}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            sx={{ color: "#af8e56" }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VenueCard;
