import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MicIcon from "@mui/icons-material/Mic";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import artistBackground from "../assets/images/backgroundArtist.png";
import clientBackground from "../assets/images/backgroundVenue.png";
import { checkUsernameAvailability, checkVenueNameAvailability, checkPhoneNumberAvailability } from "../functions/general";
import { registerArtist } from "../functions/artist";
import { registerClient } from "../functions/client";
import { useNavigate } from "react-router-dom";
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
}));

const LeftSection = styled(Box)(({ theme, background }) => ({
  width: "50%",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const RightSection = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: theme.spacing(4),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
}));

const FormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#af8e56",
  color: "#fff",
  marginTop: theme.spacing(4),
  "&:hover": {
    backgroundColor: "#af8e56",
  },
}));

const RegisterScreen = () => {
  const [role, setRole] = useState("artist"); // State for role selection
  const [username, setUsername] = useState(""); // State for username
  const [stageName, setStageName] = useState(""); // Artist specific field
  const [genres, setGenres] = useState([]); // State for artist genres
  const [venueName, setVenueName] = useState(""); // Client specific field for venue name
  const [venueType, setVenueType] = useState(""); // State for client venue type
  const [phoneNumber, setPhoneNumber] = useState(""); // State for client phone number
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [usernameError, setUsernameError] = useState(""); // State for username error
  const [venueNameError, setVenueNameError] = useState(""); // State for venue name error
  const [phoneError, setPhoneError] = useState(""); // State for phone number error
  const [emailError, setEmailError] = useState(""); // State for email error
  const [passwordError, setPasswordError] = useState(""); // State for password error
  const [authError, setAuthError] = useState(""); // State for auth-related errors
  const [formError, setFormError] = useState(""); // Form validation error
  const navigate = useNavigate();
  // Handle role selection (Artist or Client)
  const handleRoleChange = (event, newRole) => {
    if (newRole !== null) {
      setRole(newRole);
    }
  };

  // Handle genre selection
  const handleGenresChange = (event) => {
    setGenres(event.target.value);
  };

  // Handle venue type selection
  const handleVenueTypeChange = (event) => {
    setVenueType(event.target.value);
  };

  // Handle phone number input (allow only digits and validate length)
  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;

    // Only allow numbers
    if (!/^\d*$/.test(input)) {
      return;
    }

    // Validate length (between 7 and 10 digits)
    if (input.length > 10) {
      return;
    }

    setPhoneNumber(input);

    // Check if length is valid for a phone number (7 to 10 digits)
    if (input.length >= 7 && input.length <= 10) {
      setPhoneError(""); // No error
    } else {
      setPhoneError("Phone number must be between 7 and 10 digits.");
    }
  };

  // Validate that the email is in correct format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validate password based on Supabase's minimum password requirements
  const validatePassword = (password) => {
    // Supabase requires a minimum of 6 characters, but we can add other rules
    if (password.length < 6) {
      return false;
    }
    // Add additional checks (optional): require numbers, special characters, etc.
    return true;
  };

  // Validate that all required fields are filled
  const validateForm = () => {
    // Reset errors
    setEmailError("");
    setPasswordError("");
    setAuthError("");

    // Email validation
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return false;
    }

    // Password validation
    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 6 characters long.");
      return false;
    }

    // General validation
    if (!username || !email || !password) {
      setFormError("Please fill out all required fields.");
      return false;
    }

    if (role === "artist" && (!genres.length || !stageName)) {
      setFormError("Please fill out all required artist fields.");
      return false;
    }

    if (role === "client" && (!venueType || !address || !venueName || !phoneNumber || phoneError)) {
      setFormError("Please fill out all required client fields.");
      return false;
    }

    setFormError(""); // Clear any existing error if validation passes
    return true;
  };

  // Handle form submission
  const handleRegister = async () => {
    // Validate form
    if (!validateForm()) return;

    // Check username availability
    const isUsernameAvailable = await checkUsernameAvailability(username);
    if (!isUsernameAvailable) {
      setUsernameError("Username is already taken.");
      return; // Exit if username is taken
    } else {
      setUsernameError(""); // Clear any previous errors
    }

    const isPhoneNumberAvailable = await checkPhoneNumberAvailability(phoneNumber);
    if (!isPhoneNumberAvailable) {
      setPhoneError("Phone number is already registered.");
      return; // Exit if phone number is taken
    } else {
      setPhoneError(""); // Clear any previous errors
    }

    // Client-specific checks for venue name and phone number
    if (role === "client") {
      const isVenueNameAvailable = await checkVenueNameAvailability(venueName);
      if (!isVenueNameAvailable) {
        setVenueNameError("Venue name is already taken.");
        return; // Exit if venue name is taken
      } else {
        setVenueNameError(""); // Clear any previous errors
      }

      const isPhoneNumberAvailable = await checkPhoneNumberAvailability(phoneNumber);
      if (!isPhoneNumberAvailable) {
        setPhoneError("Phone number is already registered.");
        return; // Exit if phone number is taken
      } else {
        setPhoneError(""); // Clear any previous errors
      }
    }

    if (role === "artist") {
      // Register Artist
      const artistData = {
        username,
        stageName,
        genres,
        email,
        phoneNumber,
        password,
      };
      const { success, error, authError: authErr } = await registerArtist(artistData);
      if (!success) {
        if (authErr) {
          setAuthError(authErr); // Set the authError to display below the email field
        }
        console.error("Artist registration error:", error);
      } else {
        console.log("Artist registered successfully");
        navigate("/")
      }
    } else if (role === "client") {
      // Register Client
      const clientData = {
        username,
        venueName,
        venueType,
        email,
        password,
        address,
        phoneNumber,
      };
      const { success, error, authError: authErr } = await registerClient(clientData);
      if (!success) {
        if (authErr) {
          console.log(authErr);
          setAuthError(authErr); // Set the authError to display below the email field
        }
        console.error("Client registration error:", error);
      } else {
        console.log("Client registered successfully");
        navigate("/")
      }
    }
  };

  const backgroundImage = role === "artist" ? artistBackground : clientBackground;
  
  return (
    <Container>
      {/* Left Section with Dynamic Background Image */}
      <LeftSection background={backgroundImage} />

      {/* Right Section with Form */}
      <RightSection>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="body2">
            Already have an account?{" "}
            <Button href="#" style={{ color: "#af8e56", fontWeight: "bold" }} onClick={() => {navigate("/login")}}>
              Sign in
            </Button>
          </Typography>
        </Box>
        <Title variant="h4">
          Create an <span style={{ fontWeight: "bold" }}>account</span>
        </Title>
        <ToggleButtonGroup
          value={role}
          exclusive
          onChange={handleRoleChange}
          aria-label="role selection"
          style={{ marginBottom: "16px" }}
        >
          <ToggleButton value="artist" aria-label="artist">
            <MicIcon style={{ marginRight: "8px" }} /> Join as artist
          </ToggleButton>
          <ToggleButton value="client" aria-label="client">
            <LocationOnIcon style={{ marginRight: "8px" }} /> Join as client
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Common Username Field */}
        <FormRow>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!usernameError}
            helperText={usernameError}
          />
        </FormRow>

        {/* Conditional Form Fields Based on Role */}
        {role === "artist" ? (
          <>
            {/* Artist Fields */}
            <FormRow>
              <TextField
                label="Stage Name/Band Name"
                variant="outlined"
                fullWidth
                value={stageName}
                onChange={(e) => setStageName(e.target.value)}
              />
            </FormRow>
            <FormRow>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Genre(s)</InputLabel>
                <Select
                  label="Genre(s)"
                  multiple
                  value={genres}
                  onChange={handleGenresChange}
                >
                  <MenuItem value="pop">Pop</MenuItem>
                  <MenuItem value="rock">Rock</MenuItem>
                  <MenuItem value="jazz">Jazz</MenuItem>
                  <MenuItem value="hiphop">Hip-Hop</MenuItem>
                  <MenuItem value="hiphop">Other</MenuItem>
                </Select>
              </FormControl>
            </FormRow>
          </>
        ) : (
          <>
            {/* Client Fields */}

            <FormRow>
              <TextField
                label="Venue Name"
                variant="outlined"
                fullWidth
                value={venueName}
                onChange={(e) => setVenueName(e.target.value)}
                error={!!venueNameError}
                helperText={venueNameError}
              />
            </FormRow>

            <FormRow>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Venue Type</InputLabel>
                <Select
                  label="Venue Type"
                  value={venueType}
                  onChange={handleVenueTypeChange}
                >
                  <MenuItem value="restaurant">Restaurante</MenuItem>
                  <MenuItem value="bar">Bar</MenuItem>
                  <MenuItem value="club">Club</MenuItem>
                  <MenuItem value="hotel">Hotel</MenuItem>
                  <MenuItem value="private-event">Evento Privado</MenuItem>
                </Select>
              </FormControl>
            </FormRow>

            {/* <FormRow>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                error={!!phoneError}
                helperText={phoneError}
              />
            </FormRow> */}

            <FormRow>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="123 Example Street #1-21, Cali, Valle del Cauca"
              />
            </FormRow>
          </>
        )}

        {/* Common Fields */}
        <FormRow>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError || !!authError} // Display error if email is invalid or auth error
            helperText={authError || emailError} // Show auth error or validation error
          />
        </FormRow>

        <FormRow>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                error={!!phoneError}
                helperText={phoneError}
              />
            </FormRow>


        <FormRow>
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
        </FormRow>
        {formError && (
          <Typography
            color="error"
            variant="body2"
            style={{ marginBottom: "16px" }}
          >
            {formError}
          </Typography>
        )}
        <CustomButton variant="contained" fullWidth onClick={handleRegister}>
          Create account
        </CustomButton>
      </RightSection>
    </Container>
  );
};

export default RegisterScreen;