import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import "@fontsource/poppins"
import { supabase } from '../lib/helper/supabaseClient';
import { useNavigate } from 'react-router-dom'; 
import createFaroForm from './createFaroForm';
// Styled Components
const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '456px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const FormField = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  borderRadius: '8px',
  '& .MuiOutlinedInput-root': {
    borderColor: theme.palette.grey[200],
  },
}));

const ForgotPasswordLink = styled(Link)(({ theme }) => ({
  display: 'block',
  textAlign: 'center',
  color: "#AC8C46",
  marginTop: theme.spacing(2),
  fontFamily:"Poppins"
  
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#AC8C46",
  color: '#fff',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.grey[600],
  },
}));

function SignInForm() {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const navigate = useNavigate(); 

  const handleLogin = async (e) => {
    e.preventDefault();

    let email;

    // Check if the input is an email or username
    if (validateEmail(identifier)) {
      // If it's an email, use it directly
      email = identifier;
    } else {
      // Otherwise, treat it as a username and fetch the corresponding email
      const { data, error } = await supabase
        .from('users') // Your table where user info is stored
        .select('email')
        .eq('username', identifier) // Assuming you have a username column
        .single();

      if (error) {
        setErrorMessage("Username not found");
        return;
      }
      email = data.email;
    }

    // Log in with email and password
    const { data: loginData, error: loginError } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (loginError) {
      setErrorMessage(loginError.message);
    } else {
      console.log("Logged in:", loginData);
      navigate("/")
      // Redirect or handle successful login
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  return (
    <FormContainer onSubmit={handleLogin}>
      <FormField>
        {errorMessage && <p style={{ color: 'red' , fontFamily:"Poppins"}}>{errorMessage}</p>}
        <Typography variant="body2" component="label" htmlFor="username" fontFamily={"Poppins"}>
           Email <span style={{ fontWeight: '300', color: '#626262', fontFamily:'Poppins', fontSize: 16}}>*</span>
        </Typography>
        <CustomTextField
          type="text"
          id="username"
          fullWidth
          variant="outlined"
          required
          onChange={(e) => setIdentifier(e.target.value)}
        />
      </FormField>

      <FormField>
        <Typography variant="body2" component="label" htmlFor="password" fontFamily={"Poppins"}>
          Password <span style={{ fontWeight: '500', color: '#7d7d7d' }}>*</span>
        </Typography>
        <CustomTextField
          type="password"
          id="password"
          fullWidth
          variant="outlined"
          required
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormField>

      <Box display="flex" flexDirection="column" alignItems="center">
        <ForgotPasswordLink href="#" color='#AC8C46'>
          Forgot the password?
        </ForgotPasswordLink>
        <SubmitButton type="submit" fullWidth >
          Login
        </SubmitButton>
      </Box>
    </FormContainer>
  );
}

export default SignInForm;
