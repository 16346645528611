import React from 'react';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '240px',
  width: '1011px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const CapacitySelection = ({ value, onChange }) => {
  
  // Handle changes in the input field
  const handleInputChange = (event) => {
    onChange(event.target.value); // Notify parent of the change
  };

  return (
    <StyledBox>
      <Typography
        variant="body1"
        sx={{ fontWeight: 'light', color: 'text.secondary', maxWidth: '100%' }}
      >
        What is the capacity of the place?{' '}
        <Typography component="span" sx={{ fontWeight: 'medium', color: 'text.primary' }}>
          *
        </Typography>
      </Typography>
      <TextField
        id="eventCapacity"
        type="number"
        value={value}  // Controlled component
        onChange={handleInputChange} // Handle input change
        variant="outlined"
        fullWidth
        sx={{ mt: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fef0a3b30faca454cfa3392971a0891ad6bddb9067c0252d535ccadfbb7a6bae?placeholderIfAbsent=true&apiKey=7ef19faf957d428881711f97cdd2525d"
                alt=""
                style={{
                  objectFit: 'contain',
                  width: '24px',
                  height: '24px',
                }}
              />
            </InputAdornment>
          ),
          sx: { paddingRight: 3, paddingLeft: 3, minHeight: '47px' },
        }}
        aria-label="Event capacity"
      />
    </StyledBox>
  );
};

export default CapacitySelection;