export function extractTikTokVideoId(url) {
    const regex = /\/video\/(\d+)/; // Regular expression to match the video ID
    const match = url.match(regex); // Find matches
  
    return match ? match[1] : null; // Return the video ID or null if not found
  }


  export function extractInstagramVideoId(url) {
    // Use a regular expression to extract the ID from the Instagram URL
    const match = url.match(/\/(p|reel|tv)\/([a-zA-Z0-9_-]+)\//);
    return match ? match[2] : null;
}



  export function extractDomainName(url) {
    // Create an anchor element to easily parse the URL
    const anchor = document.createElement('a');
    anchor.href = url;

    // Extract the hostname (e.g., 'www.example.com')
    const hostname = anchor.hostname.replace('www.', '');

    // Split by '.' and return the first part (the domain name without extension)
    return hostname.split('.')[0];
}