import {
  AppBar,
  Button,
  Box,
  Avatar,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { supabase } from "../../lib/helper/supabaseClient";
import ofarosLogo from "../../assets/images/logo4.png";

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#171717",
  position: 'relative',
  boxShadow: 'none',
}));

const SearchButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  backgroundColor: "#af8e56",
  color: "#fff",
  fontFamily: 'Poppins',
  fontWeight: 500,
  "&:hover": {
    backgroundColor: "#af8e56",
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  flexGrow: 1,
  '& .MuiTabs-indicator': {
    backgroundColor: '#af8e56',
    height: '4px',
    top: 0,
    zIndex: 101,
  },
  position: 'relative',
  minHeight: 'unset',
  marginLeft: theme.spacing(20),
  marginRight: theme.spacing(20),
  zIndex: 100,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#e5e5e5',
  fontFamily: 'Poppins',
  fontWeight: 100,
  textTransform: 'none',
  padding: theme.spacing(3),
  flex: 1,
  '&.Mui-selected': {
    color: '#e5e5e5',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#e5e5e5',
  fontFamily: 'Poppins',
  fontWeight: 100,
  textTransform: 'none',
}));

const TopBorder = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderTop: '0.5px solid #505050',
  marginTop: theme.spacing(6),
}));

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [profilePic, setProfilePic] = useState("");
  const [user, setUser] = useState(null);

  const routeToIndex = {
    '/': 0,
    '/search': 1,
    '/dashboard/client': 2,
    '/faro_creation': 3,
  };

  const currentPath = location.pathname;
  const value = routeToIndex[currentPath] !== undefined ? routeToIndex[currentPath] : false;

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
      if (user) {
        const { data } = await supabase
          .from("artist")
          .select("profile_picture")
          .eq("id", user.id)
          .single();

        if (data) {
          setProfilePic(data.profile_picture);
        }
      }
    };

    fetchUser();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user || null);
        if (session?.user) {
          supabase
            .from("artist")
            .select("profile_picture")
            .eq("id", session.user.id)
            .single()
            .then(({ data }) => setProfilePic(data?.profile_picture || ""));
        } else {
          setProfilePic("");
        }
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/search");
        break;
      case 2:
        navigate("/dashboard/client");
        break;
      case 3:
        navigate("/faro_creation");
        break;
      default:
        break;
    }
  };

  return (
    <AppBarStyled position="static">
      <TopBorder />
      <Box
        sx={{
          justifyContent: "space-between",
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: { xs: 2, sm: 4, md: 5, lg: 10, xl: 40 },
          paddingLeft: { xs: 2, sm: 4, md: 5, lg: 10, xl: 20 },
          display: "flex",
          alignItems: "center",
          backgroundColor: "#171717",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          onClick={() => navigate("/")}
          sx={{ cursor: "pointer" }}
        >
          <img
            src={ofarosLogo}
            alt="Ofaros Logo"
            style={{ height: "45px", marginRight: "10px" }}
          />
        </Box>

        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="standard"
          TabIndicatorProps={{ children: <span /> }}
        >
          <StyledTab label="Home" />
          <StyledTab label="Artistas" />
          <StyledTab label="Reservados" />
          <StyledTab label="Reservar Evento" />
        </StyledTabs>

        <Box display="flex" alignItems="center">
          {!user ? (
            <>
              <Button
                onClick={() => {
                  navigate("/register");
                }}
                color="inherit"
                sx={{ fontFamily: 'Poppins' }}
              >
                Registrarme
              </Button>
              <SearchButton
                onClick={() => {
                  navigate("/login");
                }}
              >
                Iniciar Sesión
              </SearchButton>
            </>
          ) : (
            <>
              <Avatar
                src={profilePic}
                alt={user.email}
                sx={{ width: 30, height: 30, marginRight: 2 }}
              />
              <StyledButton
                onClick={() => {
                  supabase.auth.signOut().then(() => setUser(null));
                }}
              >
                Cerrar Sesión
              </StyledButton>
            </>
          )}
        </Box>
      </Box>
    </AppBarStyled>
  );
};

export default Header;
