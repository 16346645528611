// Import AWS SDK
import AWS from 'aws-sdk';
const config = require('../../config.json');
// Configure AWS with your credentials
AWS.config.update({
  accessKeyId: config.AWS_ACCESS_KEY_ID,
  secretAccessKey: config.AWS_SECRET_ACCESS_KEY,
  region: config.AWS_REGION, // Set your bucket region
});

// Function to upload an image to an S3 bucket
export const uploadToS3 = async (file, bucketName) => {
  const s3 = new AWS.S3();
  
  const fileBlob = new Blob([file]);
  

  // Create the params object for the S3 upload
  const params = {
    Bucket: bucketName, // Name of your S3 bucket
    Key: file.name,      // Name you want the file to have in S3
    Body: fileBlob,         // File content
    ContentType: file.type // File type
    
  };

  try {
    // Upload the file to S3
    const data = await s3.upload(params).promise();
    console.log('File uploaded successfully:', data);
    return data.Location; // Return the URL of the uploaded file
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};