import React from "react";
import { Box, Typography } from "@mui/material";
import InputField from "./InputField";

function ContactInfo({ formData, handleChange }) {
  const fields = [
    { label: "Nombre completo", id: "fullName", required: true },
    { label: "Número de teléfono", id: "phoneNumber", required: true },
    { label: "Dirección de correo electrónico", id: "emailAddress", required: true }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Typography
        variant="h2"
        sx={{ fontWeight: 'bold', color: 'text.primary', mb: 2, fontSize: "2vw" }}
      >
        Contact info
      </Typography>
      {fields.map((field) => (
        <InputField
          key={field.id}
          label={field.label}
          id={field.id}
          required={field.required}
          value={formData[field.id] || ""} // Pass the current value of the field from formData
          onChange={(e) => handleChange(field.id, e.target.value)} // Update formData through handleChange
        />
      ))}
    </Box>
  );
}

export default ContactInfo;