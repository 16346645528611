import { supabase } from "../lib/helper/supabaseClient";
import { registerAuthUser } from "./general";

// Function to register an artist
export const registerArtist = async (artistData) => {
  const { email, password } = artistData;

  try {
    // Registrar al usuario en Supabase Auth
    const { success, user, authError } = await registerAuthUser(email, password);

    if (!success) {
      console.error("Error al registrar en Auth:", authError.message || authError);
      return { success: false, message: "Hubo un problema al registrar al usuario. Inténtalo de nuevo." };
    }

    console.log("Usuario registrado en Auth con éxito:", user.id);


    return { success: true, userId: user.id };
  } catch (error) {
    console.error("Error inesperado durante el registro:", error.message || error);
    return { success: false, message: "Ocurrió un error inesperado. Por favor, inténtalo más tarde." };
  }
};


export const getArtistNameById = async (artistId) => {
  const { data, error } = await supabase
    .from("artist")
    .select("stageName")
    .eq("id", artistId)
    .single();

  if (error) {
    console.error("Error fetching artist:", error);
    throw error;
  }

  return data;
};



export const getArtistByID = async (id) => {
  const { data, error } = await supabase
    .from("artist")
    .select("*")
    .eq("id", id)
    .single();

  if (!data) {
    console.log("No matching artist found.");
    return; // You might want to return or handle this case in the UI
  }

  if (error) {
    console.error("Error fetching artist:", error);
    throw error;
  }

  return data;
};

export const getArtistsByUsername = async (username) => {
  const { data, error } = await supabase
    .from("artist")
    .select("*")
    .ilike("username", `%${username}%`);

  if (error) {
    console.error("Error fetching data:", error);
  }

  return data; // This will return the filtered rows
};
export const getArtistsStageName = async (stageName) => {
  const { data, error } = await supabase.from("artist").select("*");

  if (error) {
    console.error("Error fetching data:", error);
    return [];
  }

  // Normalize search input and results for case-insensitive matching
  return data.filter(
    (artist) =>
      artist.stageName &&
      artist.stageName.toLowerCase() === stageName.toLowerCase()
  );
};

export const getArtistsStageName2 = async (stageName) => {
  const trimmedStageName = stageName.trim();
  console.log("stageName:", JSON.stringify(trimmedStageName));
  const pattern = `%${trimmedStageName}%`;
  const { data, error } = await supabase
    .from("artist")
    .select("*")
    .ilike("stageName", pattern);

  if (error) {
    console.error("Error fetching data:", error);
    return null;
  }
  console.log("data:", data);

  return data.length > 0 ? data[0] : null;
};





export const getArtistsByGenre = async (genre) => {
  const { data, error } = await supabase.from("artist").select("*");

  if (error) {
    console.error("Error fetching artists:", error);
    throw error;
  }

  // Normalize search input and results for case-insensitive matching
  return data.filter(
    (artist) =>
      artist.genres &&
      artist.genres.some((g) => g.toLowerCase() === genre.toLowerCase())
  );
};

export const getArtistsByGenres = async (genres) => {
  if (!Array.isArray(genres) || genres.length === 0) {
    throw new Error("genres must be a non-empty array");
  }

  // Remove any spaces from genres and convert to lowercase
  const cleanedGenres = genres.map((genre) => genre.toLowerCase().trim());

  const { data, error } = await supabase.from("artist").select("*");

  if (error) {
    console.error("Error fetching artists:", error);
    throw error;
  }

  // Normalize search input and results for case-insensitive matching
  return data.filter(
    (artist) =>
      artist.genres &&
      artist.genres.some(
        (g) => cleanedGenres.includes(g.toLowerCase().trim()) // Ensure both sides are cleaned
      )
  );
};

// export const newGetArtistsByGenres = async (genres) => {
//   if (!Array.isArray(genres) || genres.length === 0) {
//     throw new Error("genres must be a non-empty array");
//   }

//   const { data, error } = await supabase.from("artist").select("");

//   if (error) {
//     console.error("Error fetching artists:", error);
//     throw error;
//   }

//   console.log("genres: ", genres);

//   // Normalize search input and results for case-insensitive matching
//   let artists = data.filter(
//     (artist) =>
//       artist.genres &&
//       artist.genres.some((g) =>
//         genres.map((genre) => genre.toLowerCase()).includes(g.toLowerCase())
//       )
//   );

//   console.log("artists: ", artists);

//   for (const artist of artists) {
//     for (let i = 0; i < artist.genres.length; i++) {
//       const { data1, error1 } = await supabase
//         .from("genre")
//         .select("*")
//         .eq("name", artist.genres[i]);
//       if (error1) {
//         console.error("Error fetching genre:", error1);
//         throw error1;
//       }
//       artist.genres[i] = data1;
//       console.log(data1);
//     }
//   }

//   return artists;
// };
