import { supabase } from "../lib/helper/supabaseClient";
import { registerAuthUser } from "./general";

export const getAllClients = async () => {
  const { data, error } = await supabase.from("client").select("*");

  if (error) {
    console.error("Error fetching data:", error);
    return;
  }

  console.log("Data from table:", data);
  return data; // This will return all the rows for client
};

export const getClientsByUsername = async (username) => {
  const { data, error } = await supabase
    .from("client")
    .select("*")
    .ilike("username", `%${username}%`);

  if (error) {
    console.error("Error fetching data:", error);
  }

  return data; // This will return the filtered rows
};

// Function to register a client
export const registerClient = async (clientData) => {
  const {
    username,
    venueName,
    venueType,
    email,
    password,
    address,
    phoneNumber,
  } = clientData;

  // First, register the user in the Supabase Auth
  const { success, user, authError } = await registerAuthUser(email, password);
  if (!success) {
    return { success: false, authError }; // Exit if auth registration fails
  }

  const { data, error } = await supabase.from("client").insert([
    {
      id: user.id,
      username,
      venueName,
      venueType,
      email,
      venueAddress: address,
      phoneNumber,
    },
  ]);

  if (error) {
    console.error("Client registration error:", error);
    return { success: false, error };
  } else {
    console.log("Client registered successfully:", data);
    return { success: true, data };
  }
};
export const getClientByID = async (id) => {
  try {
    console.log("ID: ", id);
    // f1bf2090-dcfe-427d-a9ae-ea5af3e64a0b
    const { data, error } = await supabase
      .from("client")
      .select("*")
      .eq("id", id)
      .single(); // single() ensures that only one row is returned

    if (!data) {
      console.log("No matching client found.");
      return; // You might want to return or handle this case in the UI
    }

    return data; // Return the client data
  } catch (error) {
    console.error("Error fetching client by ID:", error.message);
    throw error;
  }
};

export const fetchVenueFieldsById = async (id) => {
  try {
    console.log("Fetching venues for user ID:", id);

    const { data, error } = await supabase
      .from("client")
      .select(
        "id, venueName, venueDescription, venueDepartment, venueCity, venueAddress, venueAmountPeople, phoneNumber, venueType, venuePhoto"
      )
      .eq("id", id);

    if (error) {
      throw new Error("Error fetching data from Supabase: " + error.message);
    }

    console.log("Fetched data:", data);

    if (!data || data.length === 0) {
      throw new Error("No data found for the provided ID.");
    }

    return data; // Return all rows, as there might be multiple venues per client
  } catch (error) {
    console.error("Error fetching venue fields by ID: ", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export const updateVenueFields = async (id, updatedFields) => {
  try {
    console.log(id)
    console.log(updatedFields)
    const { data, error } = await supabase
      .from("client")
      .update(updatedFields)
      .eq("id", id)
      .select();

    if (error) {
      throw new Error("Error updating venue fields: " + error.message);
    }

    console.log("Venue updated successfully:", data);
    return { success: true, data: data[0] };
  } catch (error) {
    console.error("Unexpected error while updating venue fields:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};