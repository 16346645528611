import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getArtistNameById } from "../../functions/artist";
import { useNavigate } from "react-router-dom";

const EventCard = ({ event, onDelete }) => {
  console.log("EventCard -> event", event);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [artistName, setArtistName] = useState("Cargando...");
  const navigate = useNavigate();

  const handleModify = () => {
    navigate(`/faro_creation/${event.id}`);
  };

  useEffect(() => {
    const fetchArtistName = async () => {
      try {
        const artist = await getArtistNameById(event.artistID);
        console.log("EventCard -> artist", artist);
        if (artist && artist.stageName) {
          setArtistName(artist.stageName);
        } else {
          setArtistName("Desconocido");
        }
      } catch (error) {
        console.error("Error fetching artist:", error);
        setArtistName("Desconocido");
      }
    };

    if (event.artistID) {
      fetchArtistName();
    } else {
      setArtistName("Por definir");
    }
  }, [event.artistID]);

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    if (onDelete) {
      await onDelete(event.id);
    }
    setOpenDeleteDialog(false);
  };

  // Format the date
  const formattedDate = event.eventDate
    ? new Date(event.eventDate).toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "Por definir";

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid #d0d0d0",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Event Image */}
      {event.image && (
        <CardMedia
          component="img"
          height="140"
          image={event.image}
          sx={{
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          }}
        />
      )}

      {/* Event Details */}
      <CardContent>
        {/* Event Name */}
        <Typography gutterBottom variant="h6" component="div">
          {event.name ? event.name : "Evento Sin Nombre"}
        </Typography>

        {/* Event Date */}
        <Typography variant="body2" color="text.secondary">
          <strong>Fecha:</strong> {formattedDate}
        </Typography>

        {/* Event Budget */}
        <Typography variant="body2" color="text.secondary">
          <strong>Presupuesto:</strong>{" "}
          {event.payment ? `$${event.payment}` : "Por definir"}
        </Typography>

        {/* Event Location */}
        <Typography variant="body2" color="text.secondary">
          <strong>Dirección:</strong>{" "}
          {event.address ? event.address : "Por definir"}
        </Typography>

        {/* Artist Name */}
        <Typography variant="body2" color="text.secondary">
          <strong>Artista:</strong> {artistName}
        </Typography>

        {/* Action Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 2,
          }}
        >
          {/* Edit Button */}
          <IconButton
            aria-label="edit"
            sx={{
              color: "#FFD700",
            }}
            onClick={handleModify}
          >
            <EditIcon />
          </IconButton>

          {/* Delete Button */}
          <IconButton
            aria-label="delete"
            sx={{
              color: "red",
            }}
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardContent>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>¿Realmente quieres eliminar este Faro?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            No borrar
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Sí, estoy seguro
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EventCard;
