import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

function InputField({ label, id, required, value, onChange }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '240px',
        width: '100%',
      }}
    >
      <Typography variant="body1" sx={{ fontSize: "1vw", fontWeight: 300, color: 'text.secondary', fontFamily: "Poppins" }}>
        {label}
        {required && <Typography component="span" sx={{ fontWeight: 500, color: 'text.primary' }}>*</Typography>}
      </Typography>
      <TextField
        id={id}
        required={required}
        fullWidth
        variant="outlined"
        value={value}             // Pass the value from the parent
        onChange={onChange}       // Pass the onChange handler from the parent
        sx={{ mt: 2, minHeight: '47px', borderRadius: '8px' }}
      />
    </Box>
  );
}

export default InputField;