import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import guitarBackground from "../assets/images/backgroundGuitar.png";
import popIcon from "../assets/images/categories/pop.png";
import rockIcon from "../assets/images/categories/rock.png";
import reggaetonIcon from "../assets/images/categories/reggaeton.png";
import acousticIcon from "../assets/images/categories/acoustic.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar/SearchBar";
import { supabase } from "../lib/helper/supabaseClient";
import { getUserRoleByID } from "../functions/general";



const Root = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#0e0e0e",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${guitarBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

const HeroContent = styled(Box)(({ theme }) => ({
  flex: 1,
  maxWidth: '40%',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  textAlign: "left",
  padding: theme.spacing(0, 8, 16, 20),
}));

const ServicesSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 4),
  backgroundColor: "#fff",
  color: "#000",
  textAlign: "center",
}));

const ServiceCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  textAlign: "center",
  "& img": {
    width: "60%",
    height: "auto",
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
  },
}));




const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};


const HomepageScreen = () => {
  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {

    const checkUser = async () => {
  
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);

     
      if (user) {
        const userID = user.id;
        console.log("user id: ", userID)
        const { role } = await getUserRoleByID(userID);
        setUserRole(role); 
        console.log(`User role is: ${role}`);
        localStorage.setItem("userRole", role);
      } else {
        console.log("No user logged in.");
      }
    };

    checkUser();
  }, []);

  return (
    <div>
      <Root>
        <HeroContent>
          <Typography variant="h2" component="h1" gutterBottom>
            <span style={{ fontWeight: 100 }}>Contrata a tu artista favorito por horas</span>{" "}
            <span style={{ color: "#af8e56", fontWeight: 700 }}>y haz que tu evento brille.</span>
          </Typography>
          <Typography variant="h6" component="p" gutterBottom>
            Encuentra artistas que se adapten a la atmósfera de tu espacio y aporten un toque único a cada ocasión. Comienza tu búsqueda y deja que el talento adecuado{" "}
            <span style={{ color: "#af8e56" }}>eleve la experiencia en tu evento.</span>
          </Typography>
          <Box sx={{ display: "flex", width: "100%", mt: 2 }}>
            <SearchBar isHomePage={true} />
          </Box>
        </HeroContent>
      </Root>

      {/* Sección de Servicios Populares */}
      <ServicesSection>
        <Typography variant="h2" component="h2" gutterBottom align="left">
          <span style={{ fontWeight: 100, paddingLeft: "10%" }}>Servicios</span>{" "}
          <span style={{ color: "black", fontWeight: 700 }}>populares</span>
        </Typography>
        <Typography variant="body1" gutterBottom align="left" style={{ paddingBottom: "5%" }}>
          <span style={{ color: "#626262", fontWeight: 100, paddingLeft: "10%", display: "block" }}>
            Elige el tipo de música que más te guste. Algunos de los géneros más populares incluyen<br />
            desde ritmos clásicos como el jazz y el rock, hasta estilos más modernos como el reggaetón, la música electrónica y el pop.
          </span>
        </Typography>

        <Slider {...sliderSettings}>
          <ServiceCard>
            <img src={popIcon} alt="Pop" />
          </ServiceCard>
          <ServiceCard>
            <img src={rockIcon} alt="Rock" />
          </ServiceCard>
          <ServiceCard>
            <img src={acousticIcon} alt="Acoustic" />
          </ServiceCard>
          <ServiceCard>
            <img src={reggaetonIcon} alt="Reggaeton" />
          </ServiceCard>
        </Slider>
      </ServicesSection>

  
      
    </div>
  );
};

export default HomepageScreen;
