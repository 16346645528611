function formatDateToISO(date) {
    const padZero = (num, size = 2) => String(num).padStart(size, '0');
  
    const year = date.getUTCFullYear();
    const month = padZero(date.getUTCMonth() + 1);
    const day = padZero(date.getUTCDate());
    const hours = padZero(date.getUTCHours());
    const minutes = padZero(date.getUTCMinutes());
    const seconds = padZero(date.getUTCSeconds());
    const milliseconds = padZero(date.getUTCMilliseconds(), 6); // Ensures 6 digits for microseconds
    const timezone = '+00'; // UTC timezone
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${timezone}`;
  }
  
  export { formatDateToISO };


  const subtractDateTimes = (startTime, endTime) => {
    const start = new Date(startTime); // Parse the start date-time
    const end = new Date(endTime);     // Parse the end date-time
    
    // Calculate the difference in milliseconds
    const differenceInMillis = end.getTime() - start.getTime();
  
    // Convert milliseconds to hours, minutes, and seconds
    const hours = Math.floor(differenceInMillis / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((differenceInMillis % (1000 * 60)) / 1000);
  
    return { hours, minutes, seconds };
  };
  
export {   subtractDateTimes};