// export const green = '#53B565';//'#93C572';//'#86B049' //'#41B436';
// export const greenDark = '#40944F';
// export const yellow = '#FDE74C';
// export const greyUltraLight = '#FAFAFA';
// export const greyLight = '#E1E1E1';
// export const greyMedium = '#B7B7B7';
// export const greyDark = '#838599';
// export const greyUltraDark = '#707070';
// export const black = '#181928';//'#2C2C2C';
// export const blue = '#5979EE';
// export const brown = '#210303';
// export const orange = '#FFB53A';
// export const blueDark = '#2234e3';
// export const shadow = '#35384f';
// export const blueAlternative = "#1976d2";
// export const blueDarkAlternative = "#1565c0";
export const white = '#FFFFFF';
export const gold = "#af8e56";
export const black = "#000000";


export default {
    // green,
    // greenDark,
    // yellow,
    // greyUltraLight,
    // greyLight,
    // greyMedium,
    // greyDark,
    // greyUltraDark,
    // black,
    // white,
    // blue,
    // brown,
    // orange,
    // blueDark,
    white,
    // shadow,
    // blueAlternative,
    // blueDarkAlternative
    gold,
    black
};
