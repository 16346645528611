// src/AnalyticsTracker.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from '../Analytics';

const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        Analytics.logPageView(location.pathname + location.search);
    }, [location]);

    return null; 
};

export default AnalyticsTracker;
