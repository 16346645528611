import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogContent } from '@mui/material';
import BookingCard from './BookingCard';
import ArtistSearchScreen from '../../pages/artistSearchScreen';
import ArtistCard from '../ArtistCard/ArtistCard';

function Booking({ artist: initialArtist }) {
  const [open, setOpen] = useState(false);
  const [artist, setArtist] = useState(initialArtist);
  const [selectedArtist, setSelectedArtist] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (artist1) => {
    setOpen(false);
    if (artist1) {
      setSelectedArtist(artist1);
      setArtist(artist1);
    }
  };



  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingY: 4,
        paddingX: 4,
        borderRadius: 2,
        width: '100%',
        backgroundColor: 'grey.100',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          width: '100%',
          fontSize: '2rem',
          fontWeight: 'bold',
          color: 'black',
          marginBottom: 4,
        }}
      >
        Agendamiento
      </Typography>

      {(artist && artist.username) || selectedArtist ? (
        <ArtistCard
          artist={artist || selectedArtist}
          form={true}
      

        />
      ) : (
        <Typography
          sx={{
            padding: 8,
            textAlign: 'center',
            backgroundColor: 'grey.200',
          }}
        >
          Ningún artista seleccionado
        </Typography>
      )}


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 2,
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            padding: '10px 20px',
            fontWeight: 'medium',
            backgroundColor: 'black',
            fontFamily: 'Poppins',
          }}
        >
          {selectedArtist ? 'Cambiar selección' : 'Seleccionar artista'}
        </Button>
      </Box>

 
      <Dialog open={open} onClose={() => handleClose(null)} fullWidth maxWidth={false} sx={{ maxWidth: '85vw', margin: "auto" }}>
        <DialogContent>
          <ArtistSearchScreen popup={handleClose} />
        </DialogContent>
      </Dialog>

    </Box>
  );
}

export default Booking;
