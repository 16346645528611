import React, { useState, useEffect, useMemo } from 'react';
import InputField from '../components/FaroCreationComponents/InputField';
import CategorySelection from '../components/FaroCreationComponents/CategorySelection';
import TimeSelection from '../components/FaroCreationComponents/TimeSelection';
import CapacitySelection from '../components/FaroCreationComponents/CapacitySelection';
import ContactInfo from '../components/FaroCreationComponents/ContactInfo';
import MediaUpload from '../components/FaroCreationComponents/MediaUpload';
import Booking from '../components/FaroCreationComponents/Booking';
import ButtonContainer from '../components/FaroCreationComponents/ButtonContainer';
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { registerFaro, updateFaro } from '../functions/faro';
import { supabase } from '../lib/helper/supabaseClient';
import { formatDateToISO, subtractDateTimes } from '../lib/helper/timeConvert';
import { uploadToS3 } from '../lib/helper/uploadPictures';
import PaymentDialog from '../components/FaroCreationComponents/PaymentDialog';
import { getClientByID } from '../functions/client';


const EventForm = ({ faroData, date, startTime, endTime }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [formKey, setFormKey] = useState(Date.now());
  const artist = useMemo(
    () => location.state?.artist || { rate: 150001 },
    [location.state?.artist]
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [formData, setFormData] = useState({
    eventName: '',
    eventLocation: '',
    eventSpecification: '',
    coverCharge: '',
    category: '',
    eventDate: '',
    startTime: '',
    endTime: '',
    capacity: 100,
    contactInfo: '',
    media: null,
    booking: artist,
    fullName: '',
    phoneNumber: '',
    emailAddress: '',
    valor: 0,
  });

  useEffect(() => {
    if (date || startTime || endTime) {
      setFormData((prevData) => ({
        ...prevData,
        ...(date && { eventDate: date }),
        ...(startTime && { startTime }),
        ...(endTime && { endTime }),
      }));
      console.log('Date:', date);
      console.log('Start time:', startTime);
      console.log('End time:', endTime);
    }
  }, [date, startTime, endTime]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;

        if (user) {
          const userId = user.id;

          const clientData = await getClientByID(userId);
          if (clientData) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              fullName: clientData.username || '',
              phoneNumber: String(clientData.phoneNumber || ''),
              emailAddress: clientData.email || '',
            }));            
          }
        } else {
          setDialogMessage('Por favor, inicia sesión e inténtalo de nuevo.');
          setOpenDialog(true);
        }
      } catch (error) {
        console.error("Error al obtener los datos del cliente: ", error);
        setDialogMessage('Ocurrió un error. Por favor, inicia sesión e inténtalo de nuevo.');
        setOpenDialog(true);
      }
    };

    fetchClientData();
  }, []);




  useEffect(() => {
    if (faroData) {
      console.log('Faro data details:', faroData.details);
      setFormData({
        eventName: faroData.name || '',
        eventLocation: faroData.address || '',
        coverCharge: faroData.details.coverCharge || '',
        category: faroData.details.category || '',
        eventSpecification: faroData.details.eventSpecification || '',
        eventDate: faroData.eventDate ? faroData.eventDate.split('T')[0] : '',
        startTime: faroData.startTime ? faroData.startTime : '',
        endTime: faroData.endTime || '',
        capacity: faroData.details.capacity || 100,
        contactInfo: faroData.contactInfo || '',
        media: faroData.media || null,
        fullName: faroData.details.fullName || '',
        phoneNumber: String(faroData.details.phoneNumber || ''),
        emailAddress: faroData.details.emailAddress || '',
        valor: faroData.valor || 0,
      });
      console.log('Faro data:', faroData);
    }
  }, [faroData]);





  const [errors, setErrors] = useState({});

  const validateEventName = () => {
    if (formData.eventName.trim() === '') {
      return 'El nombre del evento es obligatorio';
    }
    return '';
  };

  const validateEventLocation = () => {
    if (formData.eventLocation.trim() === '') {
      return 'La ubicación del evento es obligatoria';
    }
    return '';
  };

  // const validateCoverCharge = () => {
  //   if (
  //     formData.coverCharge === '' ||
  //     isNaN(formData.coverCharge) ||
  //     parseFloat(formData.coverCharge) < 0
  //   ) {
  //     return 'Se requiere una tarifa de entrada válida';
  //   }
  //   return '';
  // };

  const validateCategory = () => {
    if (formData.category === '') {
      return 'La categoría es obligatoria';
    }
    return '';
  };

  const validateTime = () => {
    if (formData.time === '') {
      return 'La hora es obligatoria';
    }
    return '';
  };

  const validateCapacity = () => {
    if (
      formData.capacity === '' ||
      isNaN(formData.capacity) ||
      parseInt(formData.capacity) <= 0
    ) {
      return 'Se requiere una capacidad válida';
    }
    return '';
  };


  const validateContactInfo = () => {
    const { fullName, phoneNumber, emailAddress } = formData;
    if (fullName.trim() === '') return 'El nombre completo es obligatorio';
    if (String(phoneNumber).trim() === '') return 'El número de teléfono es obligatorio';
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress))
      return 'Se requiere una dirección de correo electrónico válida';
    return '';
  };


  const buttons = [{ text: 'Continuar y pagar', color: '#AC8C46' }];

  const CreateFaro = async (e) => {
    e.preventDefault();

    const newErrors = {
      eventName: validateEventName(),
      eventLocation: validateEventLocation(),
      // coverCharge: validateCoverCharge(),
      category: validateCategory(),
      startTime: validateStartTime(),
      endTime: validateEndTime(),
      contactInfo: validateContactInfo(),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      const errorMessages = Object.values(newErrors)
        .filter((error) => error !== '')
        .join('\n');

      setDialogMessage(`Validación fallida:\n${errorMessages}`);
      setOpenDialog(true);

      console.log('Validación fallida:', newErrors);
      return;
    }

    const now = new Date();

    const created_at_time = formatDateToISO(now);
    let file_link = null; 
    if (files && files.length > 0) {
      file_link = await uploadToS3(files[0], "ofaros-s3-storage");
    } else {
      console.log("No hay archivos para cargar.");
    }
    const duration = subtractDateTimes(formData.startTime, formData.endTime);
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();
    console.log('formData.startTime:', formData.startTime);


    try {
      await registerFaro(
        created_at_time,
        formData.eventDate,
        user.id,
        artist.id,
        formData.valor,
        formData,
        null,
        formData.eventLocation,
        formData.eventName,
        file_link,
        formData.toTime,
        formData.startTime,
        formData.endTime,
      );
      await incrementArtistFaros(artist.id);
      console.log('Form data:', formData);
      navigate('/dashboard/client');

      if (error) {
        throw new Error('Error al obtener el usuario: ' + error.message);
      }

      if (!user) {
        throw new Error('No se encontró un usuario autenticado');
      }
      console.log(user);
    } catch (error) {
      console.error('Error completo:', error);
      alert(
        'Ocurrió un error durante la creación del Faro. Revisa la consola para más detalles.'
      );
    }
  };


  const incrementArtistFaros = async (artistId) => {
    console.log("artistId:", artistId);
    try {
      const { data: currentData, error: fetchError } = await supabase
        .from('artist')
        .select('faros')
        .eq('id', artistId)
        .single();
  
      if (fetchError) throw fetchError;
  
      const newFarosValue = currentData.faros + 1;
  
      const { data, error } = await supabase
        .from('artist')
        .update({ faros: newFarosValue })
        .eq('id', artistId);
  
      if (error) throw error;
  
      console.log('Faros incrementados para el artista ID:', artistId);
      return data;
    } catch (error) {
      console.error('Error al incrementar faros:', error);
      throw error;
    }
  };
  

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleCategoryChange = (category) => {
    setFormData((prevData) => ({
      ...prevData,
      category,
    }));
    console.log('Category:', category);
  };

  const handleDateChange = (eventDate) => {
    setFormData((prevData) => ({
      ...prevData,
      eventDate,
    }));
  };

  const handleStartTimeChange = (startTime) => {
    setFormData((prevData) => ({
      ...prevData,
      startTime,
    }));
  };

  const handleEndTimeChange = (endTime) => {
    setFormData((prevData) => ({
      ...prevData,
      endTime,
    }));
  };


  const handleCapacityChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleContactInfoChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleMediaUpload = (newFiles) => {
    console.log(newFiles)
    setFiles(newFiles);
  };

  const closeDialog = () => setOpenDialog(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = async (e) => {
    e.preventDefault();

    if (faroData) {

      try {
        await actualizarFaro(e);
      } catch (error) {
        console.error('Error al actualizar el Faro:', error);
      }
    } else {
      setOpen(true);
    }
  };

  const actualizarFaro = async (e) => {
    e.preventDefault();

    const newErrors = {
      eventName: validateEventName(),
      eventLocation: validateEventLocation(),
      // coverCharge: validateCoverCharge(),
      startTime: validateStartTime(),
      endTime: validateEndTime(),
      contactInfo: validateContactInfo(),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error !== '')) {
      const errorMessages = Object.values(newErrors)
        .filter((error) => error !== '')
        .join('\n');

      setDialogMessage(`Validation failed:\n${errorMessages}`);
      setOpenDialog(true);

      console.log('Validation failed:', newErrors);
      return;
    }

    const now = new Date();
    const duration = subtractDateTimes(formData.startTime, formData.endTime);
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();

    try {
      await updateFaro(
        faroData.id,
        formData.startTime,
        formData.valor,
        formData,
        user.id,
        artist.id,
        null,
        formData.eventName,
        formData.eventLocation,
        formData.endTime,
        formData.eventDate
      );

      console.log('Faro actualizado:', formData);
      navigate('/dashboard/client');

      if (error) {
        throw new Error('Error al obtener el usuario: ' + error.message);
      }

      if (!user) {
        throw new Error('No se encontró un usuario autenticado');
      }
      console.log(user);
    } catch (error) {
      console.error('Error al actualizar el Faro:', error);
      alert(
        'Ocurrió un error durante la actualización del Faro. Revisa la consola para más detalles.'
      );
    }
  };



  const handleClose = () => {
    setOpen(false);
  };


  const validateStartTime = () => {
    if (!formData.startTime) {
      return 'La hora de inicio es obligatoria';
    }



    return '';
  };

  const validateEndTime = () => {
    if (!formData.endTime) {
      return 'La hora de fin es obligatoria';
    }


    if (formData.startTime && formData.endTime <= formData.startTime) {
      return 'La hora de fin debe ser posterior a la hora de inicio';
    }

    return '';
  };


  useEffect(() => {
    if (formData.eventDate && formData.startTime && formData.endTime) {
      const startDateTime = new Date(`${formData.eventDate}T${formData.startTime}`);
      const endDateTime = new Date(`${formData.eventDate}T${formData.endTime}`);
      const durationMs = endDateTime - startDateTime;

      if (durationMs > 0) {
        const durationMinutes = durationMs / (1000 * 60);
        const valor = (artist.rate * durationMinutes) * 1.3 / 60;
        setFormData((prevData) => ({
          ...prevData,
          valor,
        }));
      } else {
        setDialogMessage(
          'La hora de finalización debe ser después de la hora de inicio.'
        );
        setOpenDialog(true);
      }
    }
  }, [formData.eventDate, formData.startTime, formData.endTime, artist.rate]);


  return (
    <form onSubmit={CreateFaro} key={formKey}>
     
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
          alignItems: 'flex-start',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Dialog open={openDialog} onClose={closeDialog}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <Typography>{dialogMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        <InputField
          label="¿Cuál es el nombre de tu evento?"
          id="eventName"
          required
          value={formData.eventName}
          onChange={handleInputChange}
          error={!!errors.eventName}
          helperText={errors.eventName}
        />

        <CategorySelection
          value={formData.category}
          onChange={handleCategoryChange}
          error={!!errors.category}
        />

        <InputField
          label="¿Dónde es la ubicación del evento?"
          id="eventLocation"
          required
          value={formData.eventLocation}
          onChange={handleInputChange}
          error={!!errors.eventLocation}
          helperText={errors.eventLocation}
        />

        <InputField
          label="¿Alguna otra especificación?"
          id="eventSpecification"
          value={formData.eventSpecification}
          onChange={handleInputChange}
        />

        <TimeSelection
          dateValue={formData.eventDate}
          onDateChange={handleDateChange}
          startTimeValue={formData.startTime}
          onStartTimeChange={handleStartTimeChange}
          endTimeValue={formData.endTime}
          onEndTimeChange={handleEndTimeChange}
        />


        

        {/* <InputField
          label="¿Cuál es el precio de la entrada al evento?"
          id="coverCharge"
          required
          value={formData.coverCharge}
          onChange={handleInputChange}
        /> */}

        <ContactInfo
          formData={formData}
          handleChange={handleContactInfoChange}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <MediaUpload handleFileOnChange={handleMediaUpload} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Booking artist={artist}  />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mb: '50px',
            mt: '50px',
          }}
        >
          <Box display="flex" flexWrap="wrap" gap={5} alignItems="center">
            {buttons.map((button, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: { xs: '240px', sm: '407px' },
                  minHeight: '50px',
                }}
              >
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleClickOpen}
                  sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    width: '100%',
                    textTransform: 'none',
                    backgroundColor: button.color,
                  }}
                >
                  <Typography
                    variant="button"
                    fontSize="16px"
                    fontWeight="500"
                  >
                    {faroData ? ('Continuar') : ('Continuar y pagar')}
                  </Typography>
                </Button>
              </Box>
            ))}

            <PaymentDialog
              open={open}
              handleClose={handleClose}
              Valor={String((Number(formData.valor) || 0).toFixed(2))}
              onNext={CreateFaro}
            />
          </Box>
        </Box>
      </Box>
    </form>

  );
};

export default EventForm;
